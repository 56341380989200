import { languageToCode } from "./languageUtils";

type SalesOrganizations = {
  shop_url: string;
  store_id: number;
  country: string;
};

enum Environments {
  Test = "dev.",
  QA = "test.",
  ACC = "test.",
  Prod = "",
}

enum ShopNames {
  NL = "shop.eriks.nl/",
  BE = "shop.eriks.be/",
  LU = "shop.eriks.lu/",
  FR = "shop.eriks.fr/",
  CH = "shop.maagtechnic.ch/",
  UK = "shop.eriks.co.uk/",
  DE = "shop.eriks.de/",
}

export const constructUrl = (
  environment: Environments = Environments.Test,
  erpSystem: ShopNames = ShopNames.NL,
  language: string = "nl"
) => {
  return `https://${environment}${erpSystem}${language}`;
};

export const constructShoppingUrl = (
  data: any,
  language: string,
  erpSystem: string
) => {
  if (data === null || data.getSalesOrganizations === null) return "/";
  const salesOrganization = data.find(
    (org: SalesOrganizations) => org.country === erpSystem.toUpperCase()
  );
  if (!salesOrganization) return "/";
  return `${
    salesOrganization.shop_url
  }ShoppingCartDisplay?catalogId=1000&langId=${languageToCode(
    language
  )}&storeId=${salesOrganization.store_id}`;
};
